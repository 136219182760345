import { getVendureUrl } from "actions/config";

export const VENDURE_ASSETS_LOCATION = getVendureUrl();

export const ORDER_STATE_PENDING = "pending";
export const ORDER_STATE_IN_PROGRESS = "in_progress";
export const ORDER_STATE_ACCEPTED = "accepted";
export const ORDER_STATE_READY = "ready";
export const ORDER_STATE_DELIVERED = "delivered";
export const ORDER_STATE_CANCELLED = "cancelled";

export const parseState = (state, { shippingMethod }) => {
    switch (String(state).toLowerCase()) {
        case "arrangingpayment":
        case "paymentauthorized":
            return ORDER_STATE_PENDING;
        case "paymentsettled":
        case "partiallyshipped":
            return ORDER_STATE_IN_PROGRESS;
        case "shipped":
            return shippingMethod === SHIPPING_METHOD_PICKUP.code ? ORDER_STATE_READY : ORDER_STATE_IN_PROGRESS;
        case "delivered":
        case "partiallydelivered":
            return ORDER_STATE_DELIVERED;
        case "cancelled":
            return ORDER_STATE_CANCELLED;
        default:
            return state;
    }
};

export const DELIVERY_SOON = "soon";
export const DELIVERY_SCHEDULE = "schedule";

export const SHIPPING_METHOD_PICKUP = {
    id: 1,
    code: "pickup",
};

export const SHIPPING_METHOD_DELIVERY = {
    id: 2,
    code: "delivery",
    location: "Room", // default location, it will be replaced with the real location
};

export const SHIPPING_METHOD_GEOLOCATION = {
    id: 3,
    code: "geolocation",
};

export const ALLERGENS = {
    gluten: {
        id: "gluten",
        name: "Gluten",
        icon: "icon-gluten",
        color: "#D2AC6A",
    },
    crustaceans: {
        id: "crustaceans",
        name: "Crustaceans",
        icon: "icon-crustaceans",
        color: "#925245",
    },
    eggs: {
        id: "eggs",
        name: "Eggs",
        icon: "icon-eggs",
        color: "#F9AC56",
    },
    fish: {
        id: "fish",
        name: "Fish",
        icon: "icon-fish",
        color: "#4171B5",
    },
    peanuts: {
        id: "peanuts",
        name: "Peanuts",
        icon: "icon-peanuts",
        color: "#BE8D68",
    },
    soy: {
        id: "soy",
        name: "Soy",
        icon: "icon-soy",
        color: "#36B37E",
    },
    milk: {
        id: "milk",
        name: "Milk",
        icon: "icon-milk",
        color: "#643980",
    },
    nuts: {
        id: "nuts",
        name: "Nuts",
        icon: "icon-nuts",
        color: "#C16F63",
    },
    celery: {
        id: "celery",
        name: "Celery",
        icon: "icon-celery",
        color: "#90C84B",
    },
    mustard: {
        id: "mustard",
        name: "Mustard",
        icon: "icon-mustard",
        color: "#C1A661",
    },
    lupins: {
        id: "lupins",
        name: "Lupins",
        icon: "icon-lupins",
        color: "#F1DA6A",
    },
    molluscs: {
        id: "molluscs",
        name: "Molluscs",
        icon: "icon-molluscs",
        color: "#67C8DB",
    },
    sesame: {
        id: "sesame",
        name: "Sesame",
        icon: "icon-sesame",
        color: "#ABA18D",
    },
    sulphurdioxide_sulphites: {
        id: "sulphurdioxide_sulphites",
        name: "Sulphites",
        icon: "icon-so2",
        color: "#85315B",
    },
};
ALLERGENS.sesame_seeds = { ...ALLERGENS.sesame, id: "sesame_seeds" };
ALLERGENS.so2 = { ...ALLERGENS.sulphurdioxide_sulphites, id: "so2" };
ALLERGENS.sulphites = { ...ALLERGENS.sulphurdioxide_sulphites, id: "sulphites" };

export const tr = (data, lang) => {
    if (data) {
        if (lang) {
            // Expected lang
            const found = data.filter((t) => t.languageCode === lang);
            if (found.length > 0) {
                return found[0];
            }
        }
        // English
        const en = data.filter((t) => t.languageCode === "en");
        if (en.length > 0) {
            return en[0];
        }
        // Any other
        if (data.length > 0) {
            return data[0];
        }
    }
    return null;
};

// Check if string is a color. Eg: #FFFFFF, rgb(255,255,255), white
export const isColorString = (str) => (str ? String(str).match(/^(#|rgb|rgba|\w+$)/) : null);

export const encodeExtras = (selections) => {
    const extras = {};
    if (selections) {
        for (const [extraID, value] of Object.entries(selections)) {
            switch (value.type) {
                case "normal":
                    extras[extraID] = true;
                    break;
                case "numeric":
                    extras[extraID] = value?.quantity;
                    break;
                case "combobox":
                    extras[extraID] = [];
                    for (const optionID of Object.keys(value?.selections)) {
                        extras[extraID].push(optionID);
                    }
                    break;
                case "multicombobox":
                    extras[extraID] = {};
                    for (const [optionID, optionQty] of Object.entries(value?.selections)) {
                        extras[extraID][optionID] = optionQty?.quantity;
                    }
                    break;
                default:
                    break;
            }
        }
    }
    if (Object.keys(extras)?.length > 0) {
        return JSON.stringify(extras);
    }
    return null;
};

export const decodeExtras = (value) => {
    if (value) {
        const data = JSON.parse(value);
        const selections = {};
        for (const [extraID, value] of Object.entries(data)) {
            if (value === true) {
                selections[extraID] = {
                    type: "normal",
                    quantity: 1,
                };
            } else if (typeof value === "number") {
                selections[extraID] = {
                    type: "numeric",
                    quantity: value,
                };
            } else if (Array.isArray(value)) {
                selections[extraID] = {
                    type: "combobox",
                    quantity: 1,
                    selections: {},
                };
                for (const optionID of value) {
                    selections[extraID].selections[optionID] = {
                        quantity: 1,
                    };
                }
            } else if (typeof value === "object") {
                selections[extraID] = {
                    type: "multicombobox",
                    quantity: 1,
                    selections: {},
                };
                for (const [optionID, optionQty] of Object.entries(value)) {
                    selections[extraID].selections[optionID] = {
                        quantity: optionQty,
                    };
                }
            }
        }
        return selections;
    }
    return null;
};

export const printPrice = (cents, currency, lang) => {
    if (isNaN(cents)) {
        return "⚠";
    }
    const price = cents / 100;
    return currency ? price.toLocaleString(lang || "en", { style: "currency", currency }) : price;
};

export function getName(deliveryLocation, pickupLocations, lang) {
    if (deliveryLocation) {
        const translations = pickupLocations?.find((val) => val.id == deliveryLocation)?.translations;
        if (translations) {
            let value = translations.find((val) => val.languageCode == lang);
            if (value) {
                return value?.name;
            } else {
                return translations[0]?.name;
            }
        } else {
            return "-";
        }
    } else {
        return "-";
    }
}

export const extraTypes = ["normal", "numeric", "combobox", "multicombobox"];

export const getAllergen = (id) => {
    return ALLERGENS[id] || { id: id, name: id, icon: "icon-error404", color: "#FF0000" };
};

export const getAllergenIcon = (id) => {
    return getAllergen(id).icon;
};

export const getAllergenColor = (id) => {
    return getAllergen(id).color;
};

export const getAllergenName = (id) => {
    return getAllergen(id).name;
};

export const getMinutes = (time) => {
    if (time && typeof time === "string") {
        const parts = time.split(":");
        if (parts?.length >= 2) {
            const hours = parseInt(parts[0]);
            const minutes = parseInt(parts[1]);
            return hours * 60 + minutes;
        }
    }
    return 0;
};

export const displayTime = (hours, minutes, { lang }) => {
    const dateTime = new Date();
    dateTime.setHours(hours, minutes, 0, 0);
    return dateTime.toLocaleTimeString(lang || "en", {
        hour: "numeric",
        minute: "2-digit",
    });
};

export const displayTimeAndDate = (dateTime, { lang }) => {
    if (!(dateTime instanceof Date)) {
        console.error("dateTime is not a Date object", dateTime);
        return null;
    }
    if (isNaN(dateTime.getTime())) {
        console.error("dateTime is not a valid Date object", dateTime);
        return null;
    }
    return (
        dateTime.toLocaleTimeString(lang || "en", {
            hour: "2-digit",
            minute: "2-digit",
            hour12: false, // Usa formato de 24 horas para evitar el problema de AM/PM
        }) +
        " " +
        dateTime.toLocaleDateString(lang || "en", {
            day: "2-digit",
            month: "2-digit",
            year: "2-digit",
        })
    );
};

export const isOpen = (deliverySchedule, from, to) => {
    if (!from) {
        const now = new Date();
        from = now.getHours() + ":" + now.getMinutes();
    }
    if (deliverySchedule?.length > 0) {
        const fromTime = getMinutes(from);
        const toTime = to ? getMinutes(to) : null;

        let matchTime = false;
        deliverySchedule.forEach((item) => {
            if (!matchTime) {
                const start = getMinutes(item.startTime);
                const end = getMinutes(item.endTime);
                if (fromTime >= start && fromTime < end) {
                    if (!toTime || toTime <= end) {
                        matchTime = true;
                    }
                }
            }
        });
        return matchTime;
    }
    return true;
};

function getScheduleIntervals(deliverySchedule, { delayedDeliveryThreshold }) {
    const intervals = [];
    if (!deliverySchedule) {
        deliverySchedule = [
            {
                startTime: "00:00",
                endTime: "23:59",
            },
        ];
    }

    deliverySchedule.sort((a, b) => {
        const aTime = getMinutes(a.startTime);
        const bTime = getMinutes(b.startTime);
        return aTime - bTime;
    });

    for (const schedule of deliverySchedule) {
        const startTime = new Date();
        const startMin = getMinutes(schedule.startTime);
        startTime.setHours(startMin / 60, startMin % 60, 0, 0);

        const endTime = new Date();
        const endMin = getMinutes(schedule.endTime);
        endTime.setHours(endMin / 60, endMin % 60, 0, 0);

        let currentIntervalStart = startTime;

        while (currentIntervalStart < endTime) {
            const currentIntervalEnd = new Date(currentIntervalStart);
            currentIntervalEnd.setMinutes(currentIntervalEnd.getMinutes() + 30);

            // Comprobar si el intervalo supera endTime
            if (currentIntervalEnd > endTime) {
                currentIntervalEnd.setTime(endTime.getTime());
            }

            // Comprobar si el intervalo es menor a 15 minutos
            if (currentIntervalEnd - currentIntervalStart < 15 * 60 * 1000) {
                // Si es menor a 15 minutos, agrégalo al intervalo anterior
                const lastInterval = intervals[intervals.length - 1];
                lastInterval.endTime = currentIntervalEnd;
            } else {
                intervals.push({
                    startTime: currentIntervalStart,
                    endTime: currentIntervalEnd,
                });
            }
            // Establecer el inicio del próximo intervalo
            currentIntervalStart = currentIntervalEnd;
        }
    }

    // Ordenar los intervalos
    const horaActual = new Date();
    if (delayedDeliveryThreshold) {
        horaActual.setMinutes(horaActual.getMinutes() + delayedDeliveryThreshold);
    }
    const today = [];
    const tomorrow = [];

    // Separar las opciones para hoy y para mañana
    for (const i of intervals) {
        const intervalo = {
            startTime: new Date(i.startTime),
            endTime: new Date(i.endTime),
        };
        const startTime = intervalo.startTime;
        if (startTime <= horaActual) {
            intervalo.startTime.setMinutes(intervalo.startTime.getMinutes() + 24 * 60);
            intervalo.endTime.setMinutes(intervalo.endTime.getMinutes() + 24 * 60);
            tomorrow.push(intervalo);
        } else {
            today.push(intervalo);
        }
    }

    // Sort the intervals
    today.sort((a, b) => {
        const horaA = a.startTime;
        const horaB = b.startTime;
        return horaA - horaB;
    });

    tomorrow.sort((a, b) => {
        const horaA = a.startTime;
        const horaB = b.startTime;
        return horaA - horaB;
    });

    return today.concat(tomorrow);
}

export const getScheduleOptions = (deliverySchedule, { delayedDeliveryThreshold, lang }) => {
    return getScheduleIntervals(deliverySchedule, { delayedDeliveryThreshold }).map((o) => {
        const startMinutes = o.startTime.getHours() * 60 + o.startTime.getMinutes();
        const endMinutes = o.endTime.getHours() * 60 + o.endTime.getMinutes();
        const start = displayTime(startMinutes / 60, startMinutes % 60, { lang });
        const end = displayTime(endMinutes / 60, endMinutes % 60, { lang });
        const value = o.startTime.getTime() + "," + o.endTime.getTime();
        return {
            value: value,
            name: start + " - " + end,
        };
    });
};

export const nextTimeOpen = (deliverySchedule, { lang }) => {
    if (deliverySchedule?.length > 0) {
        const now = new Date();
        const current = now.getHours() * 60 + now.getMinutes();
        let nextTime;
        deliverySchedule.forEach((item) => {
            const start = getMinutes(item.startTime);
            if (current <= start && (!nextTime || start < nextTime)) {
                nextTime = start;
            }
        });
        if (!nextTime) {
            nextTime = getMinutes(deliverySchedule[0].startTime);
        }
        const hours = Math.floor(nextTime / 60);
        const minutes = nextTime % 60;
        return displayTime(hours, minutes, { lang });
    }
    return null;
};

export const getShippingMethods = (shipping, roomName) => {
    const methods = [];
    const deliveryCfg = shipping?.config?.[SHIPPING_METHOD_DELIVERY.code];
    const pickupCfg = shipping?.config?.[SHIPPING_METHOD_PICKUP.code];
    const gelocationCfg = shipping?.config?.[SHIPPING_METHOD_GEOLOCATION.code];
    if (deliveryCfg?.enabled) {
        methods.push({
            ...SHIPPING_METHOD_DELIVERY,
            ...getShippingPrice(deliveryCfg),
            location: roomName,
        });
    }
    if (pickupCfg?.enabled) {
        methods.push({
            ...SHIPPING_METHOD_PICKUP,
            ...getShippingPrice(pickupCfg),
            location: shipping?.location,
        });
    }
    if (gelocationCfg?.enabled) {
        methods.push({
            ...SHIPPING_METHOD_GEOLOCATION,
            ...getShippingPrice(gelocationCfg),
        });
    }
    return methods;
};

const getShippingPrice = (cfg) => {
    const price = parseInt(cfg?.price?.base) || 0;
    const taxRate = parseInt(cfg?.price?.taxRate) || 0;
    const priceWithTax = taxRate > 0 ? price + price * (taxRate / 100) : price;
    return {
        price,
        taxRate,
        priceWithTax,
    };
};
