import React, { useEffect, useState, useContext } from "react";
import { Wrapper, Status } from "@googlemaps/react-wrapper";
import { useTranslation } from "react-i18next";
import Loading from "./Loading";
import { Marker } from "./Marker";
import { MapComponent } from "./Map";
import useLandingSession from "hooks/useLandingSession";
import { ShopContext } from "contexts/shop";
import swal from "@sweetalert/with-react";

export const ShopCustomLocation = ({ topBarRef, lang }) => {
    const {
        setCustomLocation,
        customLocation,
        hideMap,
        setShippingMethod,
        shippingMethods,
        cart,
        geolocationTranslations,
    } = useContext(ShopContext);
    const { landing } = useLandingSession();
    const [lat, setLat] = useState(0);
    const [lng, setLng] = useState(0);
    const [myLocation, setMyLocation] = useState(customLocation);
    const [loading, setLoading] = useState(false);
    const [map, setMap] = useState();
    const { t } = useTranslation();
    const description = geolocationTranslations
        ? geolocationTranslations.find((val) => val.languageCode == lang)
        : null;
    function showPosition(position) {
        setLat(position.coords.latitude);
        setLng(position.coords.longitude);
    }

    function getLocation() {
        navigator.geolocation.getCurrentPosition(
            (position) => {
                showPosition(position);
                setLoading(false);
            },
            () => {
                swal({
                    title: t("location"),
                    buttons: {
                        confirm: t("skip"),
                    },
                    content: (
                        <div>
                            <p className="w-full text-left">{t("device-location-disabled")}</p>
                            <p className="w-full text-left">{t("you-can-do-this-in-settings")}</p>
                        </div>
                    ),
                }).then((confirm) => {
                    if (confirm) {
                        setShippingMethod(shippingMethods[0]);
                        hideMap(false);
                    }
                });
            }
        );
    }
    useEffect(() => {
        topBarRef?.current?.setTitle(t("my-location"));
        topBarRef?.current?.setBack(() => {
            if (!customLocation.lat && !customLocation.lng) {
                setShippingMethod(shippingMethods[0]);
            }
            hideMap(false);
        });
    }, [topBarRef]);

    const render = (status) => {
        switch (status) {
            case Status.LOADING:
                return <Loading></Loading>;
            case Status.FAILURE:
                return <p>Fallo</p>;
            case Status.SUCCESS:
                return (
                    <MapComponent
                        current={{ lat: lat, lng: lng }}
                        setMap={setMap}
                        map={map}
                        onClick={(e) => {
                            setMyLocation({ lat: e.latLng.lat(), lng: e.latLng.lng() });
                        }}
                    >
                        <Marker
                            position={{
                                lat: lat,
                                lng: lng,
                            }}
                            map={map}
                            title={t("my-location")}
                            icon={{
                                path: google.maps.SymbolPath.CIRCLE,
                                scale: 10,
                                fillOpacity: 1,
                                strokeWeight: 2,
                                fillColor: "#5384ED",
                                strokeColor: "#ffffff",
                            }}
                            animation={google.maps.Animation.DROP}
                        ></Marker>
                        {myLocation.lat && myLocation.lng && (
                            <Marker
                                position={myLocation}
                                map={map}
                                title={t("custom-position")}
                                onClick={() => {
                                    setMyLocation({ lat: 0, lng: 0 });
                                }}
                            ></Marker>
                        )}
                    </MapComponent>
                );
        }
    };

    useEffect(() => {
        getLocation();
    }, []);

    return (
        <>
            {!loading ? (
                <section className=" w-full has-top">
                    <div className=" flex justify-center items-center flex-col">
                        {lat != 0 && lng != 0 ? (
                            <>
                                <div className=" w-full px-3">
                                    <p className=" text-3xl border-b-2">{t("choose-your-location")}</p>
                                    {geolocationTranslations && geolocationTranslations.length > 0 && description ? (
                                        <p>{description.message}</p>
                                    ) : null}
                                    {geolocationTranslations && geolocationTranslations.length > 0 && !description ? (
                                        <p>{geolocationTranslations[0].message}</p>
                                    ) : null}
                                </div>

                                <Wrapper apiKey={landing?.googleApiKey} render={render}></Wrapper>

                                <button
                                    className=" w-3/4 py-2 mt-4 bg-accent text-accent-contrast rounded-sm"
                                    onClick={() => {
                                        if (!myLocation.lat && !myLocation.lng && lat && lng) {
                                            setCustomLocation({ lat: lat, lng: lng });
                                            setShippingMethod({
                                                id: cart.shippingMethodID,
                                                code: cart.shippingMethod,
                                                location: JSON.stringify({ lat: lat, lng: lng }),
                                            });
                                        } else if (myLocation?.lat && myLocation?.lng) {
                                            setCustomLocation(myLocation);
                                            setShippingMethod({
                                                id: cart.shippingMethodID,
                                                code: cart.shippingMethod,
                                                location: JSON.stringify(myLocation),
                                            });
                                        }
                                        hideMap(false);
                                    }}
                                >
                                    {t("confirm-my-location")}
                                </button>
                            </>
                        ) : (
                            <Loading></Loading>
                        )}
                    </div>
                </section>
            ) : (
                <Loading />
            )}
        </>
    );
};
