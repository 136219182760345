import gql from "graphql-tag";
import { CLIENT_VENDURE_SHOP, CLIENT_VENDURE_ADMIN } from "Apollo";

// Queries

export const GET_SHOPS = {
    id: "GET_SHOPS",
    query: gql`
        query getShops(
            $token: String
            $filter: shopFilters
            $projectRef: String!
            $page: Int
            $size: Int
            $orderBy: OrderBy
        ) {
            getShops(
                token: $token
                filter: $filter
                projectRef: $projectRef
                page: $page
                size: $size
                orderBy: $orderBy
            ) {
                code
                createdAt
                currencyCode
                pricesIncludeTax
                customFields {
                    allowScheduledDelivery
                    coverURL
                    defaultTax {
                        createdAt
                        customFields
                        enabled
                        id
                        name
                        updatedAt
                        value
                    }
                    defaultTaxCategory {
                        createdAt
                        customFields
                        id
                        isDefault
                        name
                        updatedAt
                        value
                    }
                    delayedDeliveryThreshold
                    deliverySchedule
                    isActive
                    autoAcceptOrders
                    isMaxProductPerOrderActive
                    isOrderCancellationAllowed
                    delayedDeliveryThreshold
                    maxProductsPerOrder
                    pickupLocation
                    shippingConfig
                }
                defaultLanguageCode
                defaultShippingZone {
                    createdAt
                    customFields
                    id
                    name
                    updatedAt
                }
                defaultTaxZone {
                    createdAt
                    customFields
                    id
                    name
                    updatedAt
                }
                destinationEmails
                destinationUsers
                id
                name
                token
                totalOrders {
                    canceled
                    delayedReady
                    delayedReceived
                    inProgress
                    pending
                    ready
                }
                updatedAt
                users
                usersInfo {
                    accountname
                    fullName
                    id
                }
            }
        }
    `,
    defaultVars: {
        projectRef: "",
        page: 1,
        size: 1000,
        token: null, // Shop token
        filter: null, // { search: "test", state: true}
        orderBy: null, // { field: "name", criteria: "ASC"}
    },
};

export const GET_COLLECTIONS = {
    id: "GET_COLLECTIONS",
    client: CLIENT_VENDURE_SHOP,
    query: gql`
        query collections {
            activeChannel {
                id
                token
                pickupLocations {
                    id
                    available
                    price {
                        withTax
                        withoutTax
                    }
                    order
                    translations {
                        languageCode
                        name
                    }
                }
                customFields {
                    pickup_location
                }
                geolocationTranslations {
                    id
                    languageCode
                    message
                }
            }
            collections(options: { filter: { enabled: { eq: true } } }) {
                items {
                    id
                    breadcrumbs {
                        id
                    }
                    parent {
                        id
                    }
                    translations {
                        name
                        languageCode
                    }
                    name
                    customFields {
                        image_url
                        can_have_children
                    }
                    productVariants {
                        items {
                            id
                            name
                            price
                            priceWithTax
                            currencyCode
                            taxRateApplied {
                                id
                            }
                            customFields {
                                max_units_per_order
                                max_units_per_order_enabled
                                are_allergens_enabled
                            }
                            product {
                                id
                                assets {
                                    id
                                    source
                                }
                                description
                                translations {
                                    languageCode
                                    name
                                    description
                                }
                                featuredAsset {
                                    id
                                    source
                                }
                                facetValues {
                                    code
                                    translations {
                                        languageCode
                                        name
                                    }
                                    facet {
                                        id
                                        code
                                        translations {
                                            languageCode
                                            name
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    `,
    defaultVars: null,
};

export const GET_PRODUCT = {
    id: "GET_PRODUCT",
    client: CLIENT_VENDURE_SHOP,
    query: gql`
        query product($id: ID!) {
            product(id: $id) {
                id
                name
                variants {
                    id
                    price
                    priceWithTax
                    currencyCode
                    taxRateApplied {
                        id
                    }
                    customFields {
                        max_units_per_order
                        max_units_per_order_enabled
                        are_allergens_enabled
                    }
                }
                assets {
                    id
                    source
                }
                description
                translations {
                    languageCode
                    name
                    description
                }
                featuredAsset {
                    id
                    source
                }
                facetValues {
                    code
                    translations {
                        languageCode
                        name
                    }
                    facet {
                        id
                        code
                        translations {
                            languageCode
                            name
                        }
                    }
                }
            }
        }
    `,
    defaultVars: { id: null },
};

export const GET_PRODUCT_EXTRAS = {
    id: "GET_PRODUCT_EXTRAS",
    client: CLIENT_VENDURE_SHOP,
    query: gql`
        query extras($productId: ID!) {
            extras(productId: $productId) {
                id
                available
                order
                type
                translations {
                    languageCode
                    name
                }
                appliesTo
                featuredAsset
                taxCategory
                options {
                    min
                    max
                    priceWithTax
                    priceWithoutTax
                    choices {
                        available
                        priceWithTax
                        priceWithoutTax
                        taxCategory
                        order
                        id
                        translations {
                            languageCode
                            name
                        }
                    }
                }
            }
        }
    `,
    defaultVars: { productId: null },
};

export const ORDER_INFO = {
    id: "ORDER_INFO",
    client: CLIENT_VENDURE_SHOP,
    query: gql`
        query orderInfo($id: ID!) {
            orderInfo(orderIds: [$id]) {
                customFields {
                    items {
                        id
                        name
                        quantity
                        extras {
                            ... on OrderInfoComboboxAndMulticomboboxExtras {
                                name
                                options {
                                    name
                                    quantity
                                }
                            }
                            ... on OrderInfoNormalAndNumericExtras {
                                name
                                quantity
                            }
                        }
                    }
                }
            }
            order(id: $id) {
                id
                updatedAt
                createdAt
                orderPlacedAt
                state
                code
                currencyCode
                subTotal
                subTotalWithTax
                shipping
                shippingWithTax
                total
                totalWithTax
                lines {
                    id
                    quantity
                    unitPrice
                    lineTax
                    productVariant {
                        id
                        name
                    }
                }
                shippingLines {
                    shippingMethod {
                        id
                        code
                    }
                }
                history {
                    items {
                        data
                    }
                }
                customFields {
                    scheduleStart
                    scheduleEnd
                    deliveryLocation
                }
            }
        }
    `,
    defaultVars: {
        id: null,
    },
};

export const ORDERS = {
    id: "ORDERS",
    client: CLIENT_VENDURE_ADMIN,
    query: gql`
        query orders($guestId: Float, $roomNumber: Float) {
            orders(
                options: {
                    filter: {
                        guestId: { eq: $guestId }
                        roomNumber: { eq: $roomNumber }
                        state: {
                            in: [
                                "PaymentAuthorized"
                                "PaymentSettled"
                                "Shipped"
                                "Delivered"
                                "PartiallyDelivered"
                                "Cancelled"
                            ]
                        }
                    }
                    sort: { updatedAt: DESC }
                }
            ) {
                items {
                    id
                    updatedAt
                    createdAt
                    orderPlacedAt
                    code
                    currencyCode
                    total
                    totalWithTax
                    totalQuantity
                    state
                    channels {
                        token
                    }
                    shippingLines {
                        shippingMethod {
                            id
                            code
                        }
                    }
                    customFields {
                        scheduleStart
                        scheduleEnd
                        deliveryLocation
                    }
                }
            }
        }
    `,
    defaultVars: {
        guestId: null,
        roomNumber: null,
    },
};

// Mutations

export const ADD_ITEM = {
    id: "ADD_ITEM",
    client: CLIENT_VENDURE_SHOP,
    query: gql`
        mutation addItemToOrder(
            $variantId: ID!
            $quantity: Int!
            $guestId: Int!
            $roomNumber: Int!
            $deliveryLocation: String
            $scheduleStart: String
            $scheduleEnd: String
            $extras: String
        ) {
            addItemToOrder(
                productVariantId: $variantId
                quantity: $quantity
                activeOrderInput: {
                    guestIdOrderStrategy: {
                        guestId: $guestId
                        roomNumber: $roomNumber
                        deliveryLocation: $deliveryLocation
                        scheduleStart: $scheduleStart
                        scheduleEnd: $scheduleEnd
                    }
                }
                customFields: { extras: $extras }
            ) {
                __typename
                ... on Order {
                    id
                    lines {
                        id
                    }
                }
                ... on OrderModificationError {
                    errorCode
                    message
                }
                ... on OrderLimitError {
                    errorCode
                    message
                    maxItems
                }
                ... on NegativeQuantityError {
                    errorCode
                    message
                }
                ... on InsufficientStockError {
                    errorCode
                    message
                    quantityAvailable
                    order {
                        id
                    }
                }
            }
        }
    `,
    defaultVars: {
        variantId: null,
        quantity: null,
        guestId: null,
        roomNumber: null,
        deliveryLocation: null,
        scheduleStart: null,
        scheduleEnd: null,
        extras: null,
    },
};

export const SET_SHIPPING_METHOD = {
    id: "SET_SHIPPING_METHOD",
    client: CLIENT_VENDURE_SHOP,
    query: gql`
        mutation setOrderShippingMethod($methodId: ID!, $guestId: Int!, $roomNumber: Int!) {
            setOrderShippingMethod(
                shippingMethodId: $methodId
                activeOrderInput: { guestIdOrderStrategy: { guestId: $guestId, roomNumber: $roomNumber } }
            ) {
                __typename
                ... on Order {
                    id
                    state
                }
                ... on OrderModificationError {
                    errorCode
                    message
                }
                ... on IneligibleShippingMethodError {
                    errorCode
                    message
                }
                ... on NoActiveOrderError {
                    errorCode
                    message
                }
            }
        }
    `,
    defaultVars: {
        methodId: null, // Shipping method ID
        guestId: null,
        roomNumber: null,
    },
};

export const ORDER_NOW = {
    id: "ORDER_NOW",
    client: CLIENT_VENDURE_SHOP,
    query: gql`
        mutation transitionOrderToState(
            $guestId: Int!
            $roomNumber: Int!
            $deliveryLocation: String
            $scheduleStart: String
            $scheduleEnd: String
        ) {
            transitionOrderToState(
                state: "ArrangingPayment"
                activeOrderInput: {
                    guestIdOrderStrategy: {
                        guestId: $guestId
                        roomNumber: $roomNumber
                        deliveryLocation: $deliveryLocation
                        scheduleStart: $scheduleStart
                        scheduleEnd: $scheduleEnd
                    }
                }
            ) {
                __typename
                ... on OrderStateTransitionError {
                    message
                    errorCode
                    transitionError
                    fromState
                    toState
                }
                ... on Order {
                    id
                }
            }
        }
    `,
    defaultVars: {
        guestId: null,
        roomNumber: null,
        deliveryLocation: null,
        scheduleStart: null, // unix int
        scheduleEnd: null, // unix int
    },
};

export const ADD_PAYMENT_METHOD = {
    id: "ADD_PAYMENT_METHOD",
    client: CLIENT_VENDURE_SHOP,
    query: gql`
        mutation addPaymentToOrder($methodCode: String!, $guestId: Int!, $roomNumber: Int!) {
            addPaymentToOrder(
                input: { method: $methodCode, metadata: {} }
                activeOrderInput: { guestIdOrderStrategy: { guestId: $guestId, roomNumber: $roomNumber } }
            ) {
                __typename
                ... on Order {
                    id
                    state
                }
                ... on OrderPaymentStateError {
                    message
                    errorCode
                }
                ... on OrderStateTransitionError {
                    message
                    errorCode
                    fromState
                    toState
                    transitionError
                }
                ... on IneligiblePaymentMethodError {
                    errorCode
                    eligibilityCheckerMessage
                    message
                }
                ... on NoActiveOrderError {
                    errorCode
                    message
                }
                ... on PaymentFailedError {
                    errorCode
                    message
                    paymentErrorMessage
                }
                ... on PaymentDeclinedError {
                    errorCode
                    message
                    paymentErrorMessage
                }
            }
        }
    `,
    defaultVars: {
        methodCode: "charge-to-room",
        guestId: null,
        roomNumber: null,
    },
};

export const ADD_ORDER_COMMENTS = {
    id: "ADD_ORDER_COMMENTS",
    client: CLIENT_VENDURE_ADMIN,
    query: gql`
        mutation addOrderNote($id: ID!, $note: String!, $isPublic: Boolean!) {
            addNoteToOrder(input: { id: $id, note: $note, isPublic: $isPublic }) {
                id
                createdAt
                updatedAt
            }
        }
    `,
    defaultVars: {
        id: null, // Order ID
        note: null, // Comments
        isPublic: true, // Public or not
    },
};

export const CANCEL_ORDER = {
    id: "CANCEL_ORDER",
    client: CLIENT_VENDURE_ADMIN,
    query: gql`
        mutation cancelOrder($id: ID!, $reason: String) {
            cancelOrder(input: { orderId: $id, reason: $reason, cancelShipping: true }) {
                __typename
                ... on EmptyOrderLineSelectionError {
                    errorCode
                    message
                }
                ... on QuantityTooGreatError {
                    errorCode
                    message
                }
                ... on MultipleOrderError {
                    errorCode
                    message
                }
                ... on CancelActiveOrderError {
                    errorCode
                    message
                    orderState
                }
                ... on OrderStateTransitionError {
                    message
                    errorCode
                    transitionError
                    fromState
                    toState
                }
                ... on Order {
                    id
                }
            }
        }
    `,
    defaultVars: {
        id: null, // Order ID
        reason: null, // Comments
    },
};
