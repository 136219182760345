import React, { useState, useEffect } from "react";
import { Redirect, Link } from "react-router-dom";
import Tabs from "../components/Tabs";
import { setSelectedHotel } from "../actions/hotel";
import { getRoomsProjects } from "../modules/rooms/store";
import { useSelector, useDispatch } from "react-redux";
import { useHotels } from "../graphql/useHotels";
import ErrorMessage from "../components/ErrorMessage";
import Loading from "../components/Loading";
import { useTranslation } from "react-i18next";
import useIsMountedRef from "../hooks/useIsMountedRef";
import { getAssetsUrl } from "../actions/config";
import { useRooms } from "../graphql/useUser";
import { getDasUrl, getLogoRef } from "../actions/config";
import { isLight } from "actions/ui";

const Hotels = () => {
    const { t } = useTranslation();

    const { loading, error, hotels, bookmarks } = useHotels({ autoload: true });
    const mounted = useIsMountedRef();
    const [redirectTo, setRedirectTo] = useState("");

    const dispatch = useDispatch();
    const roomsProjects = useSelector(getRoomsProjects);

    const { update: updateRooms } = useRooms();

    const isBookmarked = (hotelRef) => {
        if (hotelRef && bookmarks) {
            if (bookmarks.length > 0 && bookmarks.includes(hotelRef)) {
                return true;
            }
        }
        return false;
    };

    const hasRooms = (hotelRef) => hotelRef && roomsProjects && roomsProjects.includes(hotelRef);

    const goToHotel = (ref) => {
        dispatch(setSelectedHotel(ref));
        setRedirectTo("/site");
    };

    const isMinHotelsShowSearchBar = (hotelsLength) => {
        return hotelsLength > 10 ? true : false;
    };

    useEffect(() => {
        if (mounted.current) {
            let uniqueHotel = !loading && !error && hotels && hotels.length === 1;
            if (uniqueHotel) {
                dispatch(setSelectedHotel(hotels[0].ref));
                setRedirectTo("/site");
            } else {
                dispatch(setSelectedHotel(null));
            }
        }
        return () => {
            setRedirectTo("");
        };
    }, [mounted, loading, error, hotels]);

    useEffect(() => {
        updateRooms();
    }, []);

    console.log(hotels);

    if (redirectTo) {
        return <Redirect push to={redirectTo} />;
    }

    return (
        <>
            <div className="fixed z-30 w-full">
                <div className="h-16 bg-neutral text-neutral-contrast">
                    <div className="max-w-lg mx-auto flex justify-between mb-2 pt-5 px-4">
                        <h1 className="text-lg font-medium">{t("hotel", { count: 100 })}</h1>
                        {isMinHotelsShowSearchBar(hotels && hotels.length) ? (
                            <Link to="list/search">
                                <i className="icon icon-search text-xl text-accent"></i>
                            </Link>
                        ) : null}
                    </div>
                </div>
            </div>

            <section className="hotels has-tabs pt-14">
                <section>
                    {!hotels && error ? (
                        <ErrorMessage type="server" error={error} />
                    ) : loading ? (
                        <Loading />
                    ) : (
                        <ul className="hotel-list">
                            {hotels !== null
                                ? hotels
                                      .slice()
                                      .sort((a, b) => {
                                          const aMark = isBookmarked(a.ref) || hasRooms(a.ref);
                                          const bMark = isBookmarked(b.ref) || hasRooms(b.ref);
                                          if (aMark && !bMark) {
                                              return -1;
                                          } else if (bMark && !aMark) {
                                              return 1;
                                          }
                                          return a.name.toLowerCase().localeCompare(b.name.toLowerCase());
                                      })
                                      .map((hotel, index) => {
                                          return (
                                              <li
                                                  key={index}
                                                  id={`hotel-${hotel.ref}`}
                                                  onClick={() => {
                                                      goToHotel(hotel.ref);
                                                  }}
                                                  style={{
                                                      backgroundImage: "url(" + getDasUrl() + "/" + hotel.photo + ")",
                                                  }}
                                              >
                                                  <div className="overlay"></div>
                                                  &nbsp;
                                                  {isBookmarked(hotel.ref) || hasRooms(hotel.ref) ? (
                                                      <div className="absolute top-0 right-0 p-3">
                                                          <i className="icon icon-bookmark text-3xl"></i>
                                                      </div>
                                                  ) : (
                                                      ""
                                                  )}
                                                  <div className="absolute left-0 bottom-0 py-4 px-5">
                                                      <h2 className="block text-lg font-medium">{hotel.name}</h2>
                                                      {hotel.location !== null && hotel.location !== "" ? (
                                                          <>
                                                              <i className="inline-block align-text-top icon icon-location text-xl"></i>{" "}
                                                              {hotel.location}
                                                          </>
                                                      ) : null}
                                                  </div>
                                              </li>
                                          );
                                      })
                                : null}
                            {hotels === null || hotels?.length < 1 ? (
                                <p className=" w-full  text-center">{t("no-hotels-available")}</p>
                            ) : null}
                        </ul>
                    )}
                </section>
                <Tabs />
            </section>
        </>
    );
};

export default Hotels;
